<template>
  <v-app-bar class="layouts-default-app-bar pl-4 pr-4">
    <!--    <v-text-field class="__app-bar-search-field" variant="plain" placeholder="Search (Press /)" prepend-inner-icon="mdi-magnify" />-->
    <v-spacer />

    <v-btn class="mr-2" variant="text" icon="mdi-help" @click="toggleAiChat" />
    <!--    <v-btn class="mr-2" variant="text" icon="mdi-bell-outline" />-->

    <v-menu min-width="200px" rounded>
      <template #activator="{ props }">
        <v-btn icon v-bind="props">
          <v-avatar>
            <UserAvatar avatar-url="useAuth" />
          </v-avatar>
        </v-btn>
      </template>
      <v-card>
        <v-card-text>
          <div v-if="authUserStore.user" class="mx-auto text-center">
            <v-avatar class="ml-2">
              <UserAvatar avatar-url="useAuth" />
            </v-avatar>
            <h3>{{ authUserStore.user.firstName }} {{ authUserStore.user.lastName }}</h3>
            <p class="text-caption mt-1">
              {{ authUserStore.user.email }}
            </p>
            <v-divider class="my-3" />
            <v-btn to="/my-account" variant="text">
              My Account
            </v-btn>
            <v-divider class="my-3" />
            <v-btn :loading="isLoginOut" :disabled="isLoginOut" variant="text" @click="logout">
              <v-icon class="mr-2">
                mdi-logout
              </v-icon>
              Logout
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-menu>
  </v-app-bar>
</template>

<script lang="ts" setup>
import {Ref} from 'vue';
import {useAuthUserStore} from '~/store/authUser';
import {useAIBotStore} from '~/store/aiBot';

// DATA Definitions
//----------------------------------------------------------------------------------------------------------------------
const {$logout} = useNuxtApp();
const authUserStore = useAuthUserStore();
const aiBotStore = useAIBotStore();
const isLoginOut: Ref<boolean> = ref(false);

/**
 * Logout
 */
async function logout () {
  isLoginOut.value = true;
  await $logout();
}

/**
 * Toggle AI Chat bot
 */
function toggleAiChat () {
  if (aiBotStore.isVisible) {
    aiBotStore.setVisibility(false);
  } else {
    aiBotStore.setVisibility(true);
    aiBotStore.clearChat();

    setTimeout(() => {
      aiBotStore.setVariant('chat');
      aiBotStore.pushMessage({
        message: 'How can I help you?',
        role: 'ai',
        created_at: '',
      });
    }, 800);
  }
}
</script>

<style lang="scss">
.layouts-default-app-bar {
  .__app-bar-search-field {
    max-width: 320px;

    .v-field.v-field--prepended {
      align-items: center;
    }
  }
}
</style>
