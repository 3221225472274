import * as Sentry from '@sentry/vue';
import {useRuntimeConfig} from '#app';

const ignoredErrors = [
  '\'set\' on proxy: trap returned falsish for property \'$_sentrySpans\'',
];

export default defineNuxtPlugin((nuxtApp) => {
  const { vueApp } = nuxtApp;
  const config = useRuntimeConfig();

  Sentry.init({
    app: [vueApp],
    dsn: 'https://15aa49033e6940afba692017fe863f1b@o4505462564061184.ingest.sentry.io/4505475620929536',
    environment: config.public.appEnv || 'unknown',
    release: config.public.version || 'unknown',
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    beforeSend (event, hint) {
      // Check if it is an exception, and if so, log it.
      if (event.exception && !event.exception.values?.find((exception) => ignoredErrors.includes(String(exception.value)))) {
        // eslint-disable-next-line no-console
        console.error(`[Exception handled by Sentry]: (${hint.originalException})`, { event, hint });

        // Continue sending to Sentry
        return event;
      }
    },
  });

  vueApp.mixin(Sentry.createTracingMixins({ trackComponents: true, timeout: 2000, hooks: ['activate', 'mount', 'update'] }));
  Sentry.attachErrorHandler(vueApp, { logErrors: false, attachProps: true, trackComponents: true, timeout: 2000, hooks: ['activate', 'mount', 'update'] });

  return {
    provide: {
      sentrySetContext: Sentry.setContext,
      sentrySetUser: Sentry.setUser,
      sentrySetTag: Sentry.setTag,
      sentryAddBreadcrumb: Sentry.addBreadcrumb,
      sentryCaptureException: Sentry.captureException,
    },
  };
});
