/**
 * Handle all the exceptions inside the application
 *
 * @param message
 * @param error
 * @param type
 */
import {ApiReturnCode} from '~/@types/clouAuth';

const handleException = (error?: Error | null | unknown, code: ApiReturnCode = 500, type: 'info' | 'warning' | 'error' = 'error') => {
  console.error(error);
};

export default handleException;
