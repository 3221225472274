import { defineStore } from 'pinia';
import {Alert, Breadcrumb} from '~/@types/common';
import moment from 'moment';

export interface GlobalState {
  alerts: Alert[]; // Display main logo or page title
  alertIdCounter: number;
  wsUpdates: {[key: string]: string},
  breadcrumbs: Breadcrumb[],
  hasUnsavedChanges: boolean;
}

export const useGlobalStore = defineStore('global', {
  state: (): GlobalState => ({
    alerts: [],
    alertIdCounter: 0,
    wsUpdates: {},
    breadcrumbs: [],
    hasUnsavedChanges: false,
  }),
  getters: {
    //
  },
  actions: {
    setAlert (alerts: Alert[]) {
      this.alerts = alerts;
    },
    setAlertIdCounter (countingValue: number) {
      this.alertIdCounter += countingValue;
    },
    setWsUpdate (updateModel: string) {
      const updatedAt = moment().toISOString();
      if (!this.wsUpdates[updateModel]) {
        this.wsUpdates = {...this.wsUpdates, ...{[updateModel]: updatedAt}};
      } else {
        this.wsUpdates[updateModel] = updatedAt;
      }
    },
    wsShouldUpdateContent(model: string | string[], updatedAt: string | null) {
      // if (this.wsUpdates.length > 0) {
      //   const formattedModel = typeof model === 'string' ? [model] : model;
      //   return this.wsUpdates.find((wsUpdate) => formattedModel.includes(wsUpdate.model) && (!updatedAt || wsUpdate.updatedAt > updatedAt));
      // }
      // return null;
    },
    setBreadcrumbs (breadcrumbs: Breadcrumb[]) {
      this.breadcrumbs = breadcrumbs;
    },
    setHasUnsavedChanges (hasUnsavedChanges: boolean) {
      this.hasUnsavedChanges = hasUnsavedChanges;
    },
  },
});
