<template>
  <div id="__clou-web-wrapper" class="clou-web-wrapper">
    <client-only>
      <NuxtLayout>
        <div>
          <NuxtPage />
        </div>
      </NuxtLayout>
    </client-only>
  </div>
</template>

<script lang="ts" setup>
import {useGlobalStore} from '~/store/global';
const {$validateAuth} = useNuxtApp();

// DATA Definitions
//----------------------------------------------------------------------------------------------------------------------
const route = useRoute();
const globalStore = useGlobalStore();

/**
 * @_WATCH HOOK - validate authentication with every route change
 */
watch(() => route.path, () => {
  $validateAuth();
});

/**
 * @_MOUNTED Hook
 */
onMounted(() => {
  handleErrors();
});

/**
 * Handle errors
 */
function handleErrors () {
  const ignoredErrors: string[] = [];
  const messages = [
    'Uncaught NotFoundError: Failed to execute \'insertBefore\' on \'Node\': The node before which the new node is to be inserted is not a child of this node.', // chromium based
    'NotFoundError: The object can not be found here.', // safari
  ];
  if (typeof window !== 'undefined') {
    window.addEventListener('error', (ev) => {
      if (messages.includes(ev.message)) {
        ev.preventDefault();
        window.location.reload();
      } else if (ignoredErrors.includes(ev.message)) {
        // ignore error
      }
    });
  }
}

/**
 * Setup website head meta data
 */
useHead({
  titleTemplate: (titleChunk) => {
    return `${titleChunk} - NH Hub` || 'NH Hub';
  },
  meta: [
    {charset: 'utf-8'},
    {name: 'viewport', content: 'width=device-width, initial-scale=1, minimum-scale=1.0'},
    {name: 'format-detection', content: 'telephone=no'},
    {name: 'robots', content: 'index,follow'},
    {property: 'og:type', content: 'website'},
    {property: 'og:site_name', content: 'NH Hub'},
    {property: 'og:locale', content: 'en_NZ'},
    {property: 'og:image:type', content: 'image/jpg'},
    {property: 'og:image:width', content: '1200'},
    {property: 'og:image:height', content: '1200'},
  ],
});
</script>
