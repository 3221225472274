import { defineStore } from 'pinia';
import {User} from '~/@types/user';

export interface UserState {
  token: null | string;
  expireAt: null | string;
  user: User | null;
  isBetaUser: boolean;
}

export const useAuthUserStore = defineStore('authUser', {
  state: (): UserState => ({
    token: null,
    expireAt: null,
    user: null,
    isBetaUser: false,
  }),
  persist: {
    storage: persistedState.cookiesWithOptions({
      maxAge: 3600 * 1000 * 24 * 365,
    }),
  },
  actions: {
    setAuthUser (data: {token: string | null; expires_at: string} | null) {
      if (data) {
        this.token = data.token;
        this.expireAt = data.expires_at;
        this.setIsBetaUser();
      } else {
        this.token = null;
        this.expireAt = null;
        setTimeout(() => {
          this.user = null;
        }, 100);
      }
    },
    setUser (user: User) {
      this.user = user;
    },
    setIsBetaUser () {
      // Check the user meta data has betaUser flag
      if (this.user?.metaData && this.user.metaData.betaUser) {
        this.isBetaUser = true;
        return;
      }

      // Check whether this user's browser has betaUser cookie
      const betaUser = useCookie('betaUser', {maxAge: 3600 * 1000 * 24});
      if (betaUser.value) {
        this.isBetaUser = true;
      } else {
        this.isBetaUser = false;
      }
    },
  },
});
