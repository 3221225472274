<template>
  <v-img cover :src="displayAvatarUrl" />
</template>

<script lang="ts" setup>
import {useAuthUserStore} from '~/store/authUser';
import {Ref} from 'vue';
import {User} from '~/@types/user';
import blankAvatar from '~/assets/images/avatars/blank.webp';

// Props
const props = defineProps({
  avatarUrl: {
    type: String,
    required: false,
    default: 'useAuth',
  },
});

// Data
const authUserStore = useAuthUserStore();
const displayAvatarUrl: Ref<string | null> = ref((authUserStore.user as User).avatarUrl);

// Watch for store changes
watch(() => authUserStore.user, () => {
  setAvatarUrl();
}, {deep: true});

watch(() => props.avatarUrl, () => {
  setAvatarUrl();
});

/**
 * Component MOUNTED!
 */
onMounted(() => {
  setAvatarUrl();
});

/**
 * Set the prop avatar url if any provided or set the auth user avatar url
 */
function setAvatarUrl () {
  if (props.avatarUrl === 'useAuth') {
    if ((authUserStore.user as User).avatarUrl) {
      displayAvatarUrl.value = (authUserStore.user as User).avatarUrl;
    } else {
      displayAvatarUrl.value = blankAvatar;
    }
  } else if (props.avatarUrl) {
    displayAvatarUrl.value = props.avatarUrl;
  } else {
    displayAvatarUrl.value = blankAvatar;
  }
}
</script>
