<template>
  <div class="layout-guest-header">
    <v-container fluid>
      <v-row class="__guest-inner-wrapper">
        <ElementsBrandLogo />
      </v-row>
    </v-container>
  </div>
</template>

<style lang="scss">
.layout-guest-header {
  background: transparent;
  padding: 12px 20px;
  height: 65px;
  width: 100%;
  max-height: fit-content;

  .__guest-inner-wrapper {
    width: 100%;
    height: 100%;
  }
}
</style>
