import { Loader } from '@googlemaps/js-api-loader';

export default defineNuxtPlugin(({$config}) => {
  if (process.client && window && !(window as any).google) {
    const loader = new Loader({
      apiKey: $config.public.googleApiKey,
      version: 'weekly',
      libraries: ['places'],
    });

    loader.load();
  }
});

