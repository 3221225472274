import '@mdi/font/css/materialdesignicons.css';
import { VTimePicker } from 'vuetify/labs/VTimePicker';
import 'vuetify/styles';
import { createVuetify } from 'vuetify';

export default defineNuxtPlugin((app) => {
  const vuetify = createVuetify({
    theme: {
      themes: {
        light: {
          dark: false,
          colors: {
            primary: '#5e5c9a',
            secondary: '#6c778d',
            success: '#72E128',
            warning: '#FDB528',
            error: '#FF4D49',
          },
        },
      },
    },
    components: {
      VTimePicker,
    },
  });
  app.vueApp.use(vuetify);
});
