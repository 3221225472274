import {get, post} from '~/api/index';
import {alerts} from '~/composables/alerts';
import appConfig from '~/app.config';
import handleException from '~/services/exceptions';
import {useAuthUserStore} from '~/store/authUser';
import {ApiReturn, ApiReturnCode} from '~/@types/clouAuth';
import {useCompanyStore} from '~/store/comapny';

export default class AuthUserApi {
  public static resourceName = '/auth';

  /**
   * Login user
   *
   * @param data
   */
  static async login (data: {email: string; password: string; rememberMe: boolean, token: string}): Promise<ApiReturnCode> {
    try {
      // Get client IP
      let clientIp = null;
      try {
        const clientIpData: any = await $fetch('https://api.ipify.org?format=json');
        if (clientIpData && clientIpData.ip) {
          clientIp = clientIpData.ip;
        }
      } catch (error) {
        // No need to capture this error
      }

      // call the server and try to get the auth token or the error status
      const response = await post(`${this.resourceName}/login`, {...data, ...{clientIp}});

      if (response && response.code === 200 && response.data.token) {
        const router = useRouter();
        const authUserStore = useAuthUserStore();

        // Set user token
        authUserStore.setAuthUser(response.data);

        // Get user data object
        await this.get();

        setTimeout(() => {
          router.push('/');
        }, 200);

        return 200;
      }

      return response.code;
    } catch (error) {
      handleException(error);
      alerts().show(appConfig.errorMessage('login'), 'error');
      return 500;
    }
  }

  /**
   * Get authUser
   */
  static async get (): Promise<void> {
    try {
      // const params: DataTableOptions | null = useAuthUserStore().indexParams;
      const response = await get(`${this.resourceName}/user`);

      if (response.data && response.data.email) {
        const authUserStore = useAuthUserStore();
        authUserStore.setUser(response.data);

        // Set company
        if (response.data.company) {
          const companyStore = useCompanyStore();
          companyStore.setMyCompany(response.data.company);
        }
        return;
      }

      throw new Error();
    } catch (error) {
      handleException(error);
      alerts().show(appConfig.errorMessage('get your data'), 'error');
      return;
    }
  }

  /**
   * Request reset password link
   *
   * @email
   */
  static async forgotPassword (email: string, recaptchaToken: string): Promise<void> {
    try {
      // const params: DataTableOptions | null = useAuthUserStore().indexParams;
      const response = await post(`${this.resourceName}/request-password-reset-link`, {email, token: recaptchaToken});

      if (response.code === 200) {
        return;
      }

      throw new Error();
    } catch (error) {
      handleException(error);
      alerts().show(appConfig.errorMessage('generate a reset link'), 'error');
      return;
    }
  }

  /**
   * Request reset password link
   *
   * @param token
   */
  static async validateResetPasswordToken (token: string): Promise<ApiReturn> {
    try {
      // const params: DataTableOptions | null = useAuthUserStore().indexParams;
      const response = await post(`${this.resourceName}/validate-reset-password-token`, {token});

      if (response.code === 200) {
        return response;
      }

      return response;
    } catch (error) {
      handleException(error);
      alerts().show(appConfig.errorMessage('validate your request'), 'error');
      return {data: null, code: 500, error: 'Unknown'};
    }
  }

  /**
   * Reset password
   *
   * @param token
   * @param password
   */
  static async resetPassword (token: string, password: string): Promise<ApiReturnCode> {
    try {
      const response = await post(`${this.resourceName}/reset-password`, {token, password});

      return response.code;
    } catch (error) {
      handleException(error);
      alerts().show(appConfig.errorMessage('validate your request'), 'error');
      return 500;
    }
  }

  /**
   * Reset password
   *
   * @param did
   * @param guid
   */
  static async validateJoinInvitation (did: string, guid: string): Promise<ApiReturn> {
    try {
      return await post(`${this.resourceName}/validate-join-invitation`, {did, guid});
    } catch (error) {
      handleException(error);
      alerts().show(appConfig.errorMessage('validate your request'), 'error');
      return {data: null, code: 500, error: 'Unknown'};
    }
  }

  /**
   * Resend activation email
   */
  static async resendActivationEmail (email: string): Promise<ApiReturnCode> {
    try {
      const response = await post(`${this.resourceName}/resend-activation-email`, {email: email});

      return response.code;
    } catch (error) {
      handleException(error);
      alerts().show(appConfig.errorMessage('validate your request'), 'error');
      return 500;
    }
  }
}
