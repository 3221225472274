import {useAuthStore} from '~/store/auth';


/**
 * Validate token expiry and refresh token if about to expire the existing one
 *
 * @param isInitialCall
 * @param forced
 */
async function validateTokenExpiry (isInitialCall = true, forced = false) {
  if (!isInitialCall) {
    const authStore = useAuthStore();

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const diffInSeconds = Math.floor(((Math.abs(new Date() - new Date(authStore.expireAt)))/1000));
    if ((authStore.expireAt && diffInSeconds < 120) || forced) {

      // Request for refresh token
      const response = await $fetch('/refresh-token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          sessionToken: authStore.sessionToken,
        }),
      });

      if ((response as any).code === 403 && window && (!window.localStorage.getItem('refreshedByAuthSession') || window.localStorage.getItem('refreshedByAuthSession') !== 'TRUE')) {
        // Has not been refreshed and received 401 error
        console.log('refresh -1');
        window.localStorage.setItem('refreshedByAuthSession', 'TRUE');
        window.location.reload();
      } else if ((response as any).code === 403 && window && window.localStorage.getItem('refreshedByAuthSession') && window.localStorage.getItem('refreshedByAuthSession') !== 'TRUE') {
        // Has been refreshed before and still received a 403
        console.log('refresh -2');
        authStore.setHasTokenError(true);
      } else if ((response as any).code !== 403 && window && window.localStorage.getItem('refreshedByAuthSession') && window.localStorage.getItem('refreshedByAuthSession') !== 'TRUE') {
        // Has been refreshed and auth is successful
        console.log('refresh -3');
        window.localStorage.removeItem('refreshedByAuthSession');
      }

      // Store the token
      if ((response as any).code === 200 && (response as any).data && (response as any).data.token) {
        authStore.setSessionToken((response as any).data);
      }
    } else {
      window.localStorage.removeItem('refreshedByAuthSession');
    }
  }

  setTimeout(() => {
    validateTokenExpiry(false);
  }, 30000);
}

export default defineNuxtPlugin(nuxtApp => {
  validateTokenExpiry();
});
