import { defineStore } from 'pinia';
import {BotVariant, ChatHistory} from '~/@types/ai';

export interface AIBotStatus {
  isVisible: boolean;
  variant: BotVariant;
  chatHistory: ChatHistory[];
  callbackFunction: ((input: string) => any) | null;
}

export const useAIBotStore = defineStore('AIBot', {
  state: (): AIBotStatus => ({
    isVisible: false,
    variant: null,
    callbackFunction: null,
    chatHistory: [],
  }),
  actions: {
    setVisibility (isVisible: boolean) {
      this.isVisible = isVisible;
    },
    setVariant (variant: BotVariant) {
      this.variant = variant;
    },
    pushMessage (messageData: ChatHistory) {
      this.chatHistory.push(messageData);
    },
    clearChat () {
      this.chatHistory = [];
    },
    setCallbackFunction (callbackFunction: ((input: string) => any) | null) {
      this.callbackFunction = callbackFunction;
    },
  },
});
