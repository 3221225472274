import {io, Socket} from 'socket.io-client';
import {useAuthUserStore} from '~/store/authUser';
import {useGlobalStore} from '~/store/global';
import AuthUserApi from '~/api/authUserApi';
import {useRuntimeConfig} from '#app';

let socket: Socket | null = null;

/**
 * Initiate the web socket
 */
function initSocket () {
  if (!socket) {
    const authUserStore = useAuthUserStore();
    const globalStore = useGlobalStore();

    if (authUserStore.token && authUserStore.user) {
      const config = useRuntimeConfig();
      socket = io(config.public.apiUrl, {
        extraHeaders: {
          'user-did': authUserStore.user.did,
        },
        transports: ['websocket'],
        auth: {
          token: authUserStore.token,
        },
        secure: true,
      });

      socket.on('updates', (data: {model: string, data: any}) => {
        globalStore.setWsUpdate(data.model);

        // Update auth user when the following models change
        if (['UserRole', 'AuthUser'].includes(data.model)) {
          AuthUserApi.get();
        }
      });
    }
  }

  return socket;
}

export default defineNuxtPlugin((NuxtApp) => {
  return {
    provide: {
      initSocket: initSocket,
    },
  };
});
