<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <h1>{{ error.statusCode }}</h1>
        <h3>{{ errorData.title }}</h3>
      </v-col>
      <v-col cols="12">
        <img v-if="errorData.type === 'error'" :src="errorAnimation" style="height: 500px" alt="Error animation">
        <img v-if="errorData.type === 'maintenance'" :src="maintenanceAnimation" style="height: 500px" alt="Maintenance animation">
        <img v-if="errorData.type === 'access'" :src="accessDenied" style="height: 500px" alt="Access denied image">
        <img v-if="errorData.type === 'notFound'" :src="error404" style="height: 500px" alt="Page not found image">
      </v-col>
      <v-col cols="12" class="text-center">
        <p>{{ errorData.description }}</p>
        <v-btn color="primary" class="mt-5" @click="goBack">
          Go Back
        </v-btn>
      </v-col>
      <v-col v-if="config.public.appEnv === 'local'" cols="12" class="text-left">
        <pre>{{ error }}</pre>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import errorAnimation from '~/assets/images/animations/error.gif';
import maintenanceAnimation from '~/assets/images/animations/maintenance.gif';
import accessDenied from '~/assets/images/backgrounds/access-denied.jpg';
import error404 from '~/assets/images/backgrounds/404.jpg';
import {ComputedRef, PropType} from 'vue';
import {NuxtError} from '#app';

const props = defineProps({
  error: {
    type: Object as PropType<NuxtError>,
    required: true,
  },
});

const config = useRuntimeConfig();

const errorData: ComputedRef<{title: string; description: string; type: 'error' | 'maintenance' | 'access' | 'notFound'}> = computed(() => {
  switch ((props.error.statusCode.toString())) {
    case '401':
      return {
        title: 'Permission issue!',
        description: 'Sorry, It seems like you don\'t have permission to access this resource. Please contact the administrator if you believe this is a mistake.',
        type: 'access',
      };
    case '403':
      return {
        title: 'Unable to connect with the server!',
        description: 'Oops! Something went wrong while generating your access token. It could be a problem with our server, or it could be an issue with your internet connection. Please try again later or contact our support team if the problem persists.',
        type: 'error',
      };
    case '404':
      return {
        title: 'Page you requested could not be found!',
        description: 'We\'re sorry, the page you requested could not be found. Please check the URL and try again.',
        type: 'notFound',
      };
    case '503':
      return {
        title: 'We are working on something awesome!',
        description: 'We are currently performing maintenance to improve your experience. We apologize for any inconvenience this may cause and appreciate your patience. Please check back again soon for updates.',
        type: 'maintenance',
      };
    default:
      return {
        title: 'Something went wrong!',
        description: 'Oops! Something went wrong. Please try again later or contact support if the issue persists.',
        type: 'error',
      };
  }
});

function goBack () {
  clearError({ redirect: '/' });
}
</script>
