<template>
  <div>
    <div v-if="layout === 'default'" class="clou-default-layout">
      <v-container fluid>
        <v-layout>
          <LayoutsDefaultNavBar />

          <LayoutsDefaultAppBar />
          <v-main style="min-height: 300px;">
            <!-- Page Loading Bar -->
            <NuxtLoadingIndicator color="#787eff" />

            <!-- Error page -->
            <LayoutsError :error="error" />
          </v-main>

          <!-- Footer -->
          <v-footer app height="24">
            <div class="footer-author">
              <small>
                New Homes © {{ new Date().getFullYear() }} | Design & developed by <a href="https://www.clou.co.nz">Clou Digital</a>
              </small>
            </div>
            <v-spacer />
            <div class="footer-links">
              <small>
                <a href="https://google.com">Terms of use</a>
              </small>
            </div>
          </v-footer>
        </v-layout>
      </v-container>
    </div>
    <div v-else class="clou-guest-layout">
      <LayoutsGuestHeader />
      <main>
        <!-- Error page -->
        <LayoutsError :error="error" />
      </main>
    </div>
  </div>
</template>

<script setup lang="ts">
import {PropType, Ref} from 'vue';
import {NuxtError} from '#app';
import moment from 'moment';
import {useAuthUserStore} from '~/store/authUser';

// Props
const props = defineProps({
  error: {
    type: Object as PropType<NuxtError>,
    required: true,
  },
});

// Data
const layout: Ref<'default' | 'guest'> = ref('guest');
const authUserStore = useAuthUserStore();

/**
 * Component MOUNTED!
 */
onMounted(() => {
  if (authUserStore.$state.token && authUserStore.$state.user && moment(authUserStore.$state.expireAt).isAfter(moment()) && !['403', '503'].includes(props.error.statusCode.toString())) {
    layout.value = 'default';
  }
});
</script>

<style lang="scss">
body {
  background: #fff !important;
}
</style>
