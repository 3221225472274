import moment from 'moment/moment';
import {useAuthUserStore} from '~/store/authUser';
import {useCompanyStore} from '~/store/comapny';

/**
 * Validate the user authentication
 */
const validateAuth = () => {
  const user = useCookie('authUser');
  const route = useRoute();
  const authUserStore = useAuthUserStore();

  if (user && user.value && (user.value as any).expireAt) {
    if (moment((user.value as any).expireAt).isAfter(moment().add(5, 'minutes'))) {
      if (['guest', 'common'].includes(String(route.meta.layout))) {
        window.location.reload();
      }
      return;
    }
  }

  authUserStore.setAuthUser(null);
  user.value = null;


  if (!['guest', 'common'].includes(String(route.meta.layout))) {
    window.location.reload();
  }
};

/**
 * Logout the user
 */
const logout = async () => {
  try {
    const authUserStore = useAuthUserStore();
    const companyStore = useCompanyStore();
    const {$api} = useNuxtApp();
    const router = useRouter();

    const user = useCookie('user');
    user.value = null;

    authUserStore.setAuthUser(null);
    companyStore.setMyCompany(null);
    await $api.post('/auth/logout');
    router.push('/auth/login');
  } catch (error) {
    // handleException(error);
    // alerts().show(appConfig.errorMessage('logout'), 'error');
  }
};

/**
 * Export default plugin
 */
export default defineNuxtPlugin((nuxtApp) => {
  if (process.client) {
    runValidateAuth(true);
  }

  return {
    provide: {
      validateAuth,
      logout,
    },
  };
});

/**
 * Check every 10 seconds whether the auth still valid
 *
 * @param initialCall
 */
function runValidateAuth (initialCall = false) {
  if (!initialCall) {
    validateAuth();
  }
  setTimeout(() => {
    runValidateAuth();
  }, 5000);
}
