import {useCookie, useRuntimeConfig} from '#app';
import {useAuthStore} from '~/store/auth';
import {useAuthUserStore} from '~/store/authUser';
import {ApiReturn} from '~/@types/clouAuth';
import {alerts} from '~/composables/alerts';

// Create the global headers
const globalHeaders = (headers: [string, string][] = []) => {
  const config = useRuntimeConfig();
  const authStore = useAuthStore();
  const authUserStore = useAuthUserStore();

  // Maintenance access key
  const key = useCookie('key');

  // If no maintenance access key, then the access key will be filled with a fake random key string
  const timestamp = new Date().getTime(); // get current timestamp
  const randomInt = Math.floor(Math.random() * 100000000000000); // generate a random integer
  const randomString = (timestamp + randomInt).toString(36); // convert to base 36 string

  return new Headers([
    ['Accept', 'application/json'],
    ['Client-Uid', String(config.public.clientUid) || String(config.clientUid)],
    ['Session-Token', String(authStore.sessionToken)],
    ['Authorization', `Bearer ${authUserStore.token}`],
    ['Access-Key', `${key.value || randomString}`],
    ...headers,
  ]);
};

/**
 * Get the formatted url
 *  - /user/data -> baseUrl + /user/data
 *  - https://api.get-data.com -> will be the same
 * @param url
 */
const getFormattedUrl = (url: string) => {
  const config = useRuntimeConfig();

  const urlCheckRegx = /^((http|https|ftp):\/\/)/;
  if (!urlCheckRegx.test(url)) {
    return `${config.public.apiUrl}${url}`;
  }

  return url;
};

const returnError = (error: any): ApiReturn => {
  const authUserStore = useAuthUserStore();
  const ingoredErrors = [406];
  const errorCode = error.response ? error.response.status || -1 : -1;

  // Log error in Sentry
  if (!ingoredErrors.includes(errorCode)) {
    console.error(error);

    if (errorCode === 403) {
      // if (process.client) {
      //   window.location.reload();
      // }
    } else if (errorCode === 412) {
      alerts().show('Seems like you have a permission error.', 'warning', '', true, false);
    } else if (errorCode === 401) {
      authUserStore.setAuthUser(null);
      window.location.reload();
    }
  }

  let data = null;
  if (error.response && error.response.data) {
    data = error.response.data;
  }
  return {
    code: errorCode,
    data: null,
    error: data || 'Oops! Something went wrong. Please try again later.',
  };
};

/**
 * GET Request
 *
 * @param url
 * @param params
 */
export const get = async (url: string, params?: any): Promise<ApiReturn> => {
  return (await $fetch(getFormattedUrl(url),
    {
      method: 'GET',
      params,
      headers: globalHeaders(),
    })
    .catch((error) => {
      return returnError(error);
    }) as ApiReturn);
};

/**
 * POST Request
 *
 * @param url
 * @param body
 * @param headers
 */
export const post = async (url: string, body?: any, headers?: [string, string][]): Promise<ApiReturn> => {
// Make the api call
return (await $fetch(getFormattedUrl(url),
  {
    method: 'POST',
    body,
    headers: globalHeaders(headers),
  })
  .catch((error) => {
    return returnError(error);
  }) as ApiReturn);
};

/**
 * PUT Request
 *
 * @param url
 * @param body
 */
export const put = async (url: string, body?: any): Promise<ApiReturn> => {
// Make the api call
return (await $fetch(getFormattedUrl(url),
  {
    method: 'PUT',
    body,
    headers: globalHeaders(),
  })
  .catch((error) => {
    return returnError(error);
  }) as ApiReturn);
};

/**
 * DELETE Request
 *
 * @param url
 * @param body
 */
export const deletes = async (url: string, body?: any): Promise<ApiReturn> => {
// Make the api call
return (await $fetch(getFormattedUrl(url),
  {
    method: 'DELETE',
    body,
    headers: globalHeaders(),
  })
  .catch((error) => {
    return returnError(error);
  }) as ApiReturn);
};
