/**
 * Clou Auth Middleware
 * =====================================================================================================================
 * Provide a route middleware to authenticate the browser session with the server.
 *
 * There are three types of layouts:
 *  - guest: The page is accessible without authentication. Authenticated users are redirected to the home page.
 *  - common: Both authenticated and unauthenticated users can access the page.
 *  - [default]: Only authenticated users can access the page. Unauthenticated users are redirected to the login page.
 *
 */
import {useAuthUserStore} from '~/store/authUser';
import moment from 'moment/moment';

// Register middleware
export default defineNuxtRouteMiddleware((to) => {
  const authUserStore = useAuthUserStore();
  if (to.meta.layout === 'common') {
    // Common page. So we don't fucking care.
    return;
  }

  if (authUserStore.$state.token && authUserStore.$state.user && moment(authUserStore.$state.expireAt).isAfter(moment())) {
    if (to.meta.layout === 'guest') {
      // Auth user trying to access a guest page. So redirect these idiots to the home page.
      return navigateTo('/');
    }
  } else {
    if (to.meta.layout !== 'guest') {
      // Unauthenticated user trying to access an authenticated page. So redirect these mother fucking sneaky bitches to the login page.
      return navigateTo('/auth/login');
    }
  }
});
