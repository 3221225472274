import validate from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/validate.js";
import session_45auth_45global from "/vercel/path0/middleware/session-auth.global.ts";
import user_45auth_45global from "/vercel/path0/middleware/user-auth.global.ts";
import manifest_45route_45rule from "/vercel/path0/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  session_45auth_45global,
  user_45auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}