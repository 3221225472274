<template>
  <v-navigation-drawer v-model="isOpen" class="layouts-default-nav-bar" :rail="isRail" rail-width="80" width="265">
    <div class="__nav-bar-top-row">
      <transition name="fade" mode="out-in">
        <div v-if="!isRail" class="__nav-bar-logo">
          <ElementsBrandLogo />
        </div>
      </transition>
      <div class="__nav-bar-toggle-rail-wrapper">
        <v-btn variant="text" :icon="isRail ? 'mdi-chevron-double-right' : 'mdi-chevron-double-left'" @click="isRail = !isRail" />
      </div>
    </div>
    <div v-if="authUserStore.user && authUserStore.user.role" class="__nav-bar-role-wrapper">
      <img :src="companyStore.myCompany?.logoUrl || authUserStore.user.role.roleImage" alt="User role avatar">
      <transition name="fade" mode="out-in">
        <span v-if="!isRail" class="__nav-bar-role-title">{{ authUserStore.user.role.name }}</span>
      </transition>
      <strong v-if="authUserStore.isBetaUser"><small class="text-red">Beta Release Test Mode</small></strong>
    </div>

    <v-divider />

    <v-list density="compact" nav rounded="xl">
      <div v-for="navItemGroup in displayNavItems" :key="`nav-item-group-${navItemGroup.group}`">
        <v-list-subheader>{{ navItemGroup.group }}</v-list-subheader>

        <template v-for="navItem in navItemGroup.navItems" :key="`nav-item-${navItem.title}`">
          <v-list-item v-if="!navItem.subItems" :prepend-icon="navItem.icon" :title="navItem.title" :value="navItem.to" :to="navItem.to" />
          <v-list-group v-else value="Admin">
            <template #activator="{ props }">
              <v-list-item v-bind="props" :title="navItem.title" />
            </template>

            <v-list-item v-for="(subItem, key) in navItem.subItems" :key="`nav-sub-item-${key}`" class="nav-sub-item" :title="subItem.title" :prepend-icon="subItem.icon" :value="subItem.to" :to="subItem.to" />
          </v-list-group>
        </template>
      </div>
    </v-list>

    <!-- Mobile Drawer Trigger -->
    <div class="__nav-bar-mobile-drawer-trigger" :class="{'--open': isOpen}">
      <v-btn variant="flat" color="primary" :icon="isOpen ? 'mdi-chevron-left' : 'mdi-chevron-right'" @click="isOpen = !isOpen" />
    </div>
  </v-navigation-drawer>
</template>

<script setup lang="ts">
import {ComputedRef, Ref} from 'vue';
import {useAuthUserStore} from '~/store/authUser';
import {DisplayNavItem, NavItem} from '~/@types/common';
import {useCompanyStore} from '~/store/comapny';
import Access from '~/composables/access';

// DATA Definitions
//----------------------------------------------------------------------------------------------------------------------
const authUserStore = useAuthUserStore();
const companyStore = useCompanyStore();
const isOpen = ref();

/**
 * Get Company nav item
 */
const companyNavItem: ComputedRef<NavItem[]> = computed(() => {
  if (!authUserStore.user || !authUserStore.user.role) return [];

  switch (authUserStore.user.role.name.toLowerCase()) {
    case 'system admin':
    case 'nh admin':
    case 'nh manager':
      return [{title: 'Companies', icon: 'mdi-domain', to: '/companies', accessCode: 'COMPANIES-VIEW', group: 'Manage'}];
  }

  return [{title: 'My Company', icon: 'mdi-domain', to: `/companies/${companyStore.myCompany?.did}`, accessCode: 'COMPANIES-VIEW', group: 'Manage'}];
});

const userNavItem: ComputedRef<NavItem[]> = computed(() => {
  if (!authUserStore.user || !authUserStore.user.role) return [];

  const hasPermission = Access.isCompanyAdmin();
  if (hasPermission) {
    return [{title: 'Users', icon: 'mdi-account-multiple', to: '/users', accessCode: 'USERS-VIEW', group: 'Manage'}];
  }
  return [];
});

const messageNavItem: ComputedRef<NavItem[]> = computed(() => {
  if (!authUserStore.user || !authUserStore.user.role) return [];

  const roleId = access.roleId() || 10;
  if (roleId < 9) {
    return [{title: 'Messages', icon: 'mdi-message', to: '/messages', accessCode: 'ANY', group: 'Manage'}];
  }
  return [];
});

const navItems: Ref<NavItem[]> = ref([
  {title: 'Dashboard', icon: 'mdi-chart-line', to: '/', accessCode: 'ANY', group: 'Reports'},
  ...companyNavItem.value,
  {title: 'Offices', icon: 'mdi-store', to: '/offices', accessCode: 'OFFICES-MODIFY', group: 'Manage'},
  {title: 'Agents', icon: 'mdi-contacts', to: '/agents', accessCode: 'AGENTS-MODIFY', group: 'Manage'},
  {title: 'Listings', icon: 'mdi-dns', to: '/listings', accessCode: 'LISTINGS-VIEW', group: 'Manage'},
  {title: 'Designs', icon: 'mdi-brush', to: '/designs', accessCode: 'DESIGNS-VIEW', group: 'Manage'},
  {title: 'Developments', icon: 'mdi-swim', to: '/developments', accessCode: 'DEVELOPMENTS-VIEW', group: 'Manage'},
  ...userNavItem.value, // Users
  ...messageNavItem.value, // Messages
  {title: 'Roles & Permissions', icon: 'mdi-lock-open-outline', to: '/roles-and-permissions', accessCode: 'ROLES-VIEW', group: 'Manage'},
  {title: 'Billing', icon: 'mdi-credit-card-multiple', to: '/billing', accessCode: 'BILLING-VIEW', group: 'Accounting'},
  // {title: 'Revenue', icon: 'mdi-currency-usd', to: '/revenue', accessCode: 'REVENUE-VIEW', group: 'Accounting'},
  {title: 'CMS', icon: 'mdi-newspaper', to: '/cms', accessCode: 'CMS-VIEW', group: 'Website CMS'},
  {title: 'Ad Campaigns', icon: 'mdi-sale', to: '#', accessCode: 'LISTINGS-VIEW', group: 'Marketing', subItems: [
      {title: 'Facebook Meta', icon: 'mdi-facebook', to: '/ad-campaigns/facebook-meta', accessCode: 'LISTINGS-VIEW', group: 'Marketing'},
      {title: 'In-site Ads', icon: 'mdi-billboard', to: '/ad-campaigns/insite-ads', accessCode: 'LISTINGS-VIEW', group: 'Marketing'},
    ],
  },
]);

const isRail: Ref<boolean> = ref(false);

const displayNavItems: ComputedRef<DisplayNavItem[]> = computed(() => {
  let displayItemList: DisplayNavItem[] = [];
  let userPermissions: string[] | string = [];

  if (authUserStore.user && authUserStore.user.role && authUserStore.user.role.permissions && authUserStore.user.role.permissions.data) {
    userPermissions = authUserStore.user.role.permissions.data;
  }

  for(const navItem of navItems.value) {
    if (userPermissions.includes(navItem.accessCode) || userPermissions === 'FULL' || navItem.accessCode === 'ANY') {
      const thisGroupIndex = displayItemList.findIndex((displayNavItem) => displayNavItem.group === navItem.group);
      if (displayItemList[thisGroupIndex]) {
        displayItemList[thisGroupIndex].navItems.push(navItem);
      } else {
        displayItemList.push({
          group: navItem.group,
          navItems: [navItem],
        });
      }
    }
  }

  return displayItemList;
});
</script>

<style lang="scss">
.layouts-default-nav-bar {
  padding: 20px 12px;

  .__nav-bar-top-row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .__nav-bar-logo {
      img {
        width: 100%;
        height: auto;
        max-width: 160px;
      }
    }
  }

  .__nav-bar-role-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
    margin-top: 12px;

    img {
      margin-left: 5px;
      width: 100%;
      height: auto;
      max-width: 85px;
    }
  }

  .v-list {
    .v-list-item-title {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.15px;
      color: rgba(76, 78, 100, 0.87);
    }

    .v-list-subheader {
      padding: 20px 0 8px;

      .v-list-subheader__text {
        color: rgba(76, 78, 100, 0.38);
        position: relative;
      }
    }

    .nav-sub-item {
      padding-inline-start: 12px !important;
    }
  }

  @media screen and (min-width: 1280px) {
    .__nav-bar-mobile-drawer-trigger {
      display: none;
    }
  }
  .__nav-bar-mobile-drawer-trigger {
    position: absolute;
    right: -44px;
    top: 49%;
    margin: auto;
    z-index: 10;

    &.--open {
      right: -19px
    }

    .v-btn {
      background: #fff;
      border-top-right-radius: 8px;
      width: 18px;
      border-bottom-right-radius: 8px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}
</style>
