import {useAuthUserStore} from '~/store/authUser';
import {useCompanyStore} from '~/store/comapny';

const verify = (accessCode: string) => {
  const authUserStore = useAuthUserStore();

  if (authUserStore.user && authUserStore.user.role && authUserStore.user.role.permissions && authUserStore.user.role.permissions.data) {
    if (authUserStore.user.role.permissions.data === 'FULL') {
      return true;
    }
    return authUserStore.user.role.permissions.data.includes(accessCode);
  }
  return false;
};

const isSystemAdmin = () => {
  const authUserStore = useAuthUserStore();
  return !!(authUserStore.user && authUserStore.user.roleId === 1);
};

/**
 * Check if the user is admin
 */
const isAdmin = () => {
  const authUserStore = useAuthUserStore();

  if (authUserStore.user) {
    if (authUserStore.user.role && authUserStore.user.role.permissions && authUserStore.user.role.permissions.data && authUserStore.user.role.permissions.data === 'FULL') {
      return true;
    } else if (authUserStore.user.roleId < 4) {
      return true;
    }
  }
  return false;
};

/**
 * Check if the user is company admin
 */
const isCompanyOwner = () => {
  const authUserStore = useAuthUserStore();
  return !!(authUserStore.user && authUserStore.user.role && authUserStore.user.roleId === 4);
};

/**
 * Check if the user is company admin
 */
const isCompanyAdmin = () => {
  const authUserStore = useAuthUserStore();
  return !!(authUserStore.user && authUserStore.user.role && authUserStore.user.roleId <= 5);
};

/**
 * Check if the user is admin or this company admin
 *
 * @param companyDid
 */
const isAdminOrIsThisCompanyAdmin = (companyDid: string) => {
  const companyStore = useCompanyStore();
  if (isAdmin()) {
    return true;
  } else if (isCompanyAdmin() && companyStore.myCompany && companyStore.myCompany.did === companyDid) {
    return true;
  }
  return false;
};

/**
 * Get the company DID of the user (admin users get null as they dont have a company)
 */
const myCompanyDid = () => {
  const companyStore = useCompanyStore();
  return companyStore.myCompany ? companyStore.myCompany.did : null;
};

const roleId = () => {
  const authUserStore = useAuthUserStore();
  return authUserStore.user ? authUserStore.user.roleId : null;
};

export default {verify, isSystemAdmin, isAdmin, isCompanyOwner, isCompanyAdmin, isAdminOrIsThisCompanyAdmin, myCompanyDid, roleId};
