import { default as facebook_45metaj7zxijfN30Meta } from "/vercel/path0/pages/ad-campaigns/facebook-meta.vue?macro=true";
import { default as _91did_93G3HYXfC2WdMeta } from "/vercel/path0/pages/ad-campaigns/insite-ads/[did].vue?macro=true";
import { default as indexCv871qZ0MUMeta } from "/vercel/path0/pages/ad-campaigns/insite-ads/index.vue?macro=true";
import { default as _91did_93AJkTtx6ZKQMeta } from "/vercel/path0/pages/agents/[did].vue?macro=true";
import { default as indexv6DqD3uL3AMeta } from "/vercel/path0/pages/agents/index.vue?macro=true";
import { default as indexNwsz7tEVRZMeta } from "/vercel/path0/pages/auth/company-signup/index.vue?macro=true";
import { default as indexNwoJG7pLnGMeta } from "/vercel/path0/pages/auth/forgot-password/index.vue?macro=true";
import { default as _91guid_93aspe5zk1jZMeta } from "/vercel/path0/pages/auth/join-invitation/[did]/[guid].vue?macro=true";
import { default as indexSrqw7Nv0JTMeta } from "/vercel/path0/pages/auth/login/index.vue?macro=true";
import { default as _91token_93RK0OYd6HQiMeta } from "/vercel/path0/pages/auth/reset-password/[token].vue?macro=true";
import { default as indexILkRmdrwqDMeta } from "/vercel/path0/pages/billing/index.vue?macro=true";
import { default as _91did_932CL77cBIeIMeta } from "/vercel/path0/pages/cms/[did].vue?macro=true";
import { default as indexgFNCiAlBCaMeta } from "/vercel/path0/pages/cms/index.vue?macro=true";
import { default as _91did_93dqdnDlO2kyMeta } from "/vercel/path0/pages/companies/[did].vue?macro=true";
import { default as indexlGQ7fs3ZlBMeta } from "/vercel/path0/pages/companies/index.vue?macro=true";
import { default as _91did_934BZBOHOJ3vMeta } from "/vercel/path0/pages/designs/[did].vue?macro=true";
import { default as indexgWO4HwM6UzMeta } from "/vercel/path0/pages/designs/index.vue?macro=true";
import { default as _91did_93BajMzPQx84Meta } from "/vercel/path0/pages/developments/[did].vue?macro=true";
import { default as index86EVX0gvkwMeta } from "/vercel/path0/pages/developments/index.vue?macro=true";
import { default as _91_46_46_46slug_93UUgP4euXUtMeta } from "/vercel/path0/pages/docs/[...slug].vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as _91did_93aGmF40w4tuMeta } from "/vercel/path0/pages/listings/[did].vue?macro=true";
import { default as indexN2jJ9vdJFFMeta } from "/vercel/path0/pages/listings/index.vue?macro=true";
import { default as indexpt683rA6b7Meta } from "/vercel/path0/pages/messages/index.vue?macro=true";
import { default as index9uLfdLkXniMeta } from "/vercel/path0/pages/my-account/index.vue?macro=true";
import { default as _91did_93Yt67LAsleDMeta } from "/vercel/path0/pages/offices/[did].vue?macro=true";
import { default as indexEcThDmaCl2Meta } from "/vercel/path0/pages/offices/index.vue?macro=true";
import { default as _91redirectionKey_937mdlrQuCGFMeta } from "/vercel/path0/pages/redirect/[redirectionKey].vue?macro=true";
import { default as _91slug_93QFaZKYgjbZMeta } from "/vercel/path0/pages/roles-and-permissions/[slug].vue?macro=true";
import { default as indexnHul2UI6IEMeta } from "/vercel/path0/pages/roles-and-permissions/index.vue?macro=true";
import { default as _91did_938MgTtMf21QMeta } from "/vercel/path0/pages/system/[did].vue?macro=true";
import { default as indexnpJzOswtosMeta } from "/vercel/path0/pages/system/index.vue?macro=true";
import { default as _91did_93focqIwSvJBMeta } from "/vercel/path0/pages/users/[did].vue?macro=true";
import { default as indexOXtObJcgq9Meta } from "/vercel/path0/pages/users/index.vue?macro=true";
export default [
  {
    name: facebook_45metaj7zxijfN30Meta?.name ?? "ad-campaigns-facebook-meta",
    path: facebook_45metaj7zxijfN30Meta?.path ?? "/ad-campaigns/facebook-meta",
    meta: facebook_45metaj7zxijfN30Meta || {},
    alias: facebook_45metaj7zxijfN30Meta?.alias || [],
    redirect: facebook_45metaj7zxijfN30Meta?.redirect,
    component: () => import("/vercel/path0/pages/ad-campaigns/facebook-meta.vue").then(m => m.default || m)
  },
  {
    name: _91did_93G3HYXfC2WdMeta?.name ?? "ad-campaigns-insite-ads-did",
    path: _91did_93G3HYXfC2WdMeta?.path ?? "/ad-campaigns/insite-ads/:did()",
    meta: _91did_93G3HYXfC2WdMeta || {},
    alias: _91did_93G3HYXfC2WdMeta?.alias || [],
    redirect: _91did_93G3HYXfC2WdMeta?.redirect,
    component: () => import("/vercel/path0/pages/ad-campaigns/insite-ads/[did].vue").then(m => m.default || m)
  },
  {
    name: indexCv871qZ0MUMeta?.name ?? "ad-campaigns-insite-ads",
    path: indexCv871qZ0MUMeta?.path ?? "/ad-campaigns/insite-ads",
    meta: indexCv871qZ0MUMeta || {},
    alias: indexCv871qZ0MUMeta?.alias || [],
    redirect: indexCv871qZ0MUMeta?.redirect,
    component: () => import("/vercel/path0/pages/ad-campaigns/insite-ads/index.vue").then(m => m.default || m)
  },
  {
    name: _91did_93AJkTtx6ZKQMeta?.name ?? "agents-did",
    path: _91did_93AJkTtx6ZKQMeta?.path ?? "/agents/:did()",
    meta: _91did_93AJkTtx6ZKQMeta || {},
    alias: _91did_93AJkTtx6ZKQMeta?.alias || [],
    redirect: _91did_93AJkTtx6ZKQMeta?.redirect,
    component: () => import("/vercel/path0/pages/agents/[did].vue").then(m => m.default || m)
  },
  {
    name: indexv6DqD3uL3AMeta?.name ?? "agents",
    path: indexv6DqD3uL3AMeta?.path ?? "/agents",
    meta: indexv6DqD3uL3AMeta || {},
    alias: indexv6DqD3uL3AMeta?.alias || [],
    redirect: indexv6DqD3uL3AMeta?.redirect,
    component: () => import("/vercel/path0/pages/agents/index.vue").then(m => m.default || m)
  },
  {
    name: indexNwsz7tEVRZMeta?.name ?? "auth-company-signup",
    path: indexNwsz7tEVRZMeta?.path ?? "/auth/company-signup",
    meta: indexNwsz7tEVRZMeta || {},
    alias: indexNwsz7tEVRZMeta?.alias || [],
    redirect: indexNwsz7tEVRZMeta?.redirect,
    component: () => import("/vercel/path0/pages/auth/company-signup/index.vue").then(m => m.default || m)
  },
  {
    name: indexNwoJG7pLnGMeta?.name ?? "auth-forgot-password",
    path: indexNwoJG7pLnGMeta?.path ?? "/auth/forgot-password",
    meta: indexNwoJG7pLnGMeta || {},
    alias: indexNwoJG7pLnGMeta?.alias || [],
    redirect: indexNwoJG7pLnGMeta?.redirect,
    component: () => import("/vercel/path0/pages/auth/forgot-password/index.vue").then(m => m.default || m)
  },
  {
    name: _91guid_93aspe5zk1jZMeta?.name ?? "auth-join-invitation-did-guid",
    path: _91guid_93aspe5zk1jZMeta?.path ?? "/auth/join-invitation/:did()/:guid()",
    meta: _91guid_93aspe5zk1jZMeta || {},
    alias: _91guid_93aspe5zk1jZMeta?.alias || [],
    redirect: _91guid_93aspe5zk1jZMeta?.redirect,
    component: () => import("/vercel/path0/pages/auth/join-invitation/[did]/[guid].vue").then(m => m.default || m)
  },
  {
    name: indexSrqw7Nv0JTMeta?.name ?? "auth-login",
    path: indexSrqw7Nv0JTMeta?.path ?? "/auth/login",
    meta: indexSrqw7Nv0JTMeta || {},
    alias: indexSrqw7Nv0JTMeta?.alias || [],
    redirect: indexSrqw7Nv0JTMeta?.redirect,
    component: () => import("/vercel/path0/pages/auth/login/index.vue").then(m => m.default || m)
  },
  {
    name: _91token_93RK0OYd6HQiMeta?.name ?? "auth-reset-password-token",
    path: _91token_93RK0OYd6HQiMeta?.path ?? "/auth/reset-password/:token()",
    meta: _91token_93RK0OYd6HQiMeta || {},
    alias: _91token_93RK0OYd6HQiMeta?.alias || [],
    redirect: _91token_93RK0OYd6HQiMeta?.redirect,
    component: () => import("/vercel/path0/pages/auth/reset-password/[token].vue").then(m => m.default || m)
  },
  {
    name: indexILkRmdrwqDMeta?.name ?? "billing",
    path: indexILkRmdrwqDMeta?.path ?? "/billing",
    meta: indexILkRmdrwqDMeta || {},
    alias: indexILkRmdrwqDMeta?.alias || [],
    redirect: indexILkRmdrwqDMeta?.redirect,
    component: () => import("/vercel/path0/pages/billing/index.vue").then(m => m.default || m)
  },
  {
    name: _91did_932CL77cBIeIMeta?.name ?? "cms-did",
    path: _91did_932CL77cBIeIMeta?.path ?? "/cms/:did()",
    meta: _91did_932CL77cBIeIMeta || {},
    alias: _91did_932CL77cBIeIMeta?.alias || [],
    redirect: _91did_932CL77cBIeIMeta?.redirect,
    component: () => import("/vercel/path0/pages/cms/[did].vue").then(m => m.default || m)
  },
  {
    name: indexgFNCiAlBCaMeta?.name ?? "cms",
    path: indexgFNCiAlBCaMeta?.path ?? "/cms",
    meta: indexgFNCiAlBCaMeta || {},
    alias: indexgFNCiAlBCaMeta?.alias || [],
    redirect: indexgFNCiAlBCaMeta?.redirect,
    component: () => import("/vercel/path0/pages/cms/index.vue").then(m => m.default || m)
  },
  {
    name: _91did_93dqdnDlO2kyMeta?.name ?? "companies-did",
    path: _91did_93dqdnDlO2kyMeta?.path ?? "/companies/:did()",
    meta: _91did_93dqdnDlO2kyMeta || {},
    alias: _91did_93dqdnDlO2kyMeta?.alias || [],
    redirect: _91did_93dqdnDlO2kyMeta?.redirect,
    component: () => import("/vercel/path0/pages/companies/[did].vue").then(m => m.default || m)
  },
  {
    name: indexlGQ7fs3ZlBMeta?.name ?? "companies",
    path: indexlGQ7fs3ZlBMeta?.path ?? "/companies",
    meta: indexlGQ7fs3ZlBMeta || {},
    alias: indexlGQ7fs3ZlBMeta?.alias || [],
    redirect: indexlGQ7fs3ZlBMeta?.redirect,
    component: () => import("/vercel/path0/pages/companies/index.vue").then(m => m.default || m)
  },
  {
    name: _91did_934BZBOHOJ3vMeta?.name ?? "designs-did",
    path: _91did_934BZBOHOJ3vMeta?.path ?? "/designs/:did()",
    meta: _91did_934BZBOHOJ3vMeta || {},
    alias: _91did_934BZBOHOJ3vMeta?.alias || [],
    redirect: _91did_934BZBOHOJ3vMeta?.redirect,
    component: () => import("/vercel/path0/pages/designs/[did].vue").then(m => m.default || m)
  },
  {
    name: indexgWO4HwM6UzMeta?.name ?? "designs",
    path: indexgWO4HwM6UzMeta?.path ?? "/designs",
    meta: indexgWO4HwM6UzMeta || {},
    alias: indexgWO4HwM6UzMeta?.alias || [],
    redirect: indexgWO4HwM6UzMeta?.redirect,
    component: () => import("/vercel/path0/pages/designs/index.vue").then(m => m.default || m)
  },
  {
    name: _91did_93BajMzPQx84Meta?.name ?? "developments-did",
    path: _91did_93BajMzPQx84Meta?.path ?? "/developments/:did()",
    meta: _91did_93BajMzPQx84Meta || {},
    alias: _91did_93BajMzPQx84Meta?.alias || [],
    redirect: _91did_93BajMzPQx84Meta?.redirect,
    component: () => import("/vercel/path0/pages/developments/[did].vue").then(m => m.default || m)
  },
  {
    name: index86EVX0gvkwMeta?.name ?? "developments",
    path: index86EVX0gvkwMeta?.path ?? "/developments",
    meta: index86EVX0gvkwMeta || {},
    alias: index86EVX0gvkwMeta?.alias || [],
    redirect: index86EVX0gvkwMeta?.redirect,
    component: () => import("/vercel/path0/pages/developments/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93UUgP4euXUtMeta?.name ?? "docs-slug",
    path: _91_46_46_46slug_93UUgP4euXUtMeta?.path ?? "/docs/:slug(.*)*",
    meta: _91_46_46_46slug_93UUgP4euXUtMeta || {},
    alias: _91_46_46_46slug_93UUgP4euXUtMeta?.alias || [],
    redirect: _91_46_46_46slug_93UUgP4euXUtMeta?.redirect,
    component: () => import("/vercel/path0/pages/docs/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91did_93aGmF40w4tuMeta?.name ?? "listings-did",
    path: _91did_93aGmF40w4tuMeta?.path ?? "/listings/:did()",
    meta: _91did_93aGmF40w4tuMeta || {},
    alias: _91did_93aGmF40w4tuMeta?.alias || [],
    redirect: _91did_93aGmF40w4tuMeta?.redirect,
    component: () => import("/vercel/path0/pages/listings/[did].vue").then(m => m.default || m)
  },
  {
    name: indexN2jJ9vdJFFMeta?.name ?? "listings",
    path: indexN2jJ9vdJFFMeta?.path ?? "/listings",
    meta: indexN2jJ9vdJFFMeta || {},
    alias: indexN2jJ9vdJFFMeta?.alias || [],
    redirect: indexN2jJ9vdJFFMeta?.redirect,
    component: () => import("/vercel/path0/pages/listings/index.vue").then(m => m.default || m)
  },
  {
    name: indexpt683rA6b7Meta?.name ?? "messages",
    path: indexpt683rA6b7Meta?.path ?? "/messages",
    meta: indexpt683rA6b7Meta || {},
    alias: indexpt683rA6b7Meta?.alias || [],
    redirect: indexpt683rA6b7Meta?.redirect,
    component: () => import("/vercel/path0/pages/messages/index.vue").then(m => m.default || m)
  },
  {
    name: index9uLfdLkXniMeta?.name ?? "my-account",
    path: index9uLfdLkXniMeta?.path ?? "/my-account",
    meta: index9uLfdLkXniMeta || {},
    alias: index9uLfdLkXniMeta?.alias || [],
    redirect: index9uLfdLkXniMeta?.redirect,
    component: () => import("/vercel/path0/pages/my-account/index.vue").then(m => m.default || m)
  },
  {
    name: _91did_93Yt67LAsleDMeta?.name ?? "offices-did",
    path: _91did_93Yt67LAsleDMeta?.path ?? "/offices/:did()",
    meta: _91did_93Yt67LAsleDMeta || {},
    alias: _91did_93Yt67LAsleDMeta?.alias || [],
    redirect: _91did_93Yt67LAsleDMeta?.redirect,
    component: () => import("/vercel/path0/pages/offices/[did].vue").then(m => m.default || m)
  },
  {
    name: indexEcThDmaCl2Meta?.name ?? "offices",
    path: indexEcThDmaCl2Meta?.path ?? "/offices",
    meta: indexEcThDmaCl2Meta || {},
    alias: indexEcThDmaCl2Meta?.alias || [],
    redirect: indexEcThDmaCl2Meta?.redirect,
    component: () => import("/vercel/path0/pages/offices/index.vue").then(m => m.default || m)
  },
  {
    name: _91redirectionKey_937mdlrQuCGFMeta?.name ?? "redirect-redirectionKey",
    path: _91redirectionKey_937mdlrQuCGFMeta?.path ?? "/redirect/:redirectionKey()",
    meta: _91redirectionKey_937mdlrQuCGFMeta || {},
    alias: _91redirectionKey_937mdlrQuCGFMeta?.alias || [],
    redirect: _91redirectionKey_937mdlrQuCGFMeta?.redirect,
    component: () => import("/vercel/path0/pages/redirect/[redirectionKey].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QFaZKYgjbZMeta?.name ?? "roles-and-permissions-slug",
    path: _91slug_93QFaZKYgjbZMeta?.path ?? "/roles-and-permissions/:slug()",
    meta: _91slug_93QFaZKYgjbZMeta || {},
    alias: _91slug_93QFaZKYgjbZMeta?.alias || [],
    redirect: _91slug_93QFaZKYgjbZMeta?.redirect,
    component: () => import("/vercel/path0/pages/roles-and-permissions/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexnHul2UI6IEMeta?.name ?? "roles-and-permissions",
    path: indexnHul2UI6IEMeta?.path ?? "/roles-and-permissions",
    meta: indexnHul2UI6IEMeta || {},
    alias: indexnHul2UI6IEMeta?.alias || [],
    redirect: indexnHul2UI6IEMeta?.redirect,
    component: () => import("/vercel/path0/pages/roles-and-permissions/index.vue").then(m => m.default || m)
  },
  {
    name: _91did_938MgTtMf21QMeta?.name ?? "system-did",
    path: _91did_938MgTtMf21QMeta?.path ?? "/system/:did()",
    meta: _91did_938MgTtMf21QMeta || {},
    alias: _91did_938MgTtMf21QMeta?.alias || [],
    redirect: _91did_938MgTtMf21QMeta?.redirect,
    component: () => import("/vercel/path0/pages/system/[did].vue").then(m => m.default || m)
  },
  {
    name: indexnpJzOswtosMeta?.name ?? "system",
    path: indexnpJzOswtosMeta?.path ?? "/system",
    meta: indexnpJzOswtosMeta || {},
    alias: indexnpJzOswtosMeta?.alias || [],
    redirect: indexnpJzOswtosMeta?.redirect,
    component: () => import("/vercel/path0/pages/system/index.vue").then(m => m.default || m)
  },
  {
    name: _91did_93focqIwSvJBMeta?.name ?? "users-did",
    path: _91did_93focqIwSvJBMeta?.path ?? "/users/:did()",
    meta: _91did_93focqIwSvJBMeta || {},
    alias: _91did_93focqIwSvJBMeta?.alias || [],
    redirect: _91did_93focqIwSvJBMeta?.redirect,
    component: () => import("/vercel/path0/pages/users/[did].vue").then(m => m.default || m)
  },
  {
    name: indexOXtObJcgq9Meta?.name ?? "users",
    path: indexOXtObJcgq9Meta?.path ?? "/users",
    meta: indexOXtObJcgq9Meta || {},
    alias: indexOXtObJcgq9Meta?.alias || [],
    redirect: indexOXtObJcgq9Meta?.redirect,
    component: () => import("/vercel/path0/pages/users/index.vue").then(m => m.default || m)
  }
]