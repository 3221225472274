import {Alert, AlertTypes} from '~/@types/common';
import {useGlobalStore} from '~/store/global';

export const alerts = () => ({
  /**
   * Show alert in the system
   *
   * @param alertMessage
   * @param alertType
   * @param errorDetails
   * @param dismissible
   * @param displayDurationInMs
   * @param autoDismiss
   * @param action
   */
  show (
    alertMessage: string,
    alertType: AlertTypes = 'success',
    errorDetails: string | null = null,
    dismissible = true,
    autoDismiss = true,
    displayDurationInMs = 5000,
    action?: {label: string; to: string}) {
    const globalStore = useGlobalStore();

    // Set alert
    const alerts: Alert[] = Object.assign([], globalStore.alerts);
    const lastAlertId: number = globalStore.alertIdCounter;
    const newAlertId = lastAlertId + 1;

    // Log error to Sentry
    if (alertType === 'error' && errorDetails !== null) {
      const error = `Error msg: ${alertMessage}, Error details: ${errorDetails}`;
    }

    // Set default error
    if (alertType === 'error' && (!alertMessage || alertMessage.length < 2)) {
      alertMessage = 'Oops! something went wrong. Please try again later.';
    }

    alerts.push({
      id: newAlertId,
      message: alertMessage,
      type: alertType,
      dismissible,
      displayDuration: alertType === 'success' ? 5000 : 10000,
      autoDismiss,
      action,
    });

    globalStore.setAlert(alerts);
    globalStore.setAlertIdCounter(+1);

    if (autoDismiss) {
      setTimeout(() => {
        this.dismiss(newAlertId);
      }, displayDurationInMs);
    }

    return newAlertId;
  },

  /**
   * Dismiss the alert by the given id
   *
   * @param alertId
   */
  dismiss (alertId: number) {
    const globalStore = useGlobalStore();
    const alerts: Alert[] = Object.assign([], globalStore.alerts);
    for (const alert of alerts) {
      if (alert.id === alertId) {
        const index = alerts.indexOf(alert);
        alerts.splice(index, 1);
      }
    }
    globalStore.setAlert(alerts);
  },
});
