/**
 * Clou Auth Middleware
 * =====================================================================================================================
 * Provide a route middleware to authenticate the browser session with the server.
 *
 * To generate the session token it requires the secret key and the client uid. Send those two to the server
 *  and get a session token to send to the client side.
 */
import {useCookie, useNuxtApp, useRuntimeConfig} from '#app';
import {useAuthStore} from '~/store/auth';

// Register middleware
export default async function () {
  await authenticateSession();
}

/**
 * Authenticate the session and generate the session token
 */
async function authenticateSession () {
  const nuxtApp = useNuxtApp();
  const authStore = useAuthStore(); // Where the generated session token data stored
  const runtimeConfig = useRuntimeConfig();

  // If the auth token exists, just return
  const authToken = authStore.sessionToken;
  if (authToken || process.client) {
    // If the session auth token exists or the code is running on the client side, just return. Never expose the secret key to the client side!!!
    return;
  }

  const {apiSecret} = useRuntimeConfig();

  // Get the auth secret key
  if (!apiSecret) {
    if (typeof nuxtApp.$sentryCaptureException === 'function' && process.env.NUXT_APP_ENV === 'production') {
      // Trigger secret key not found error
      nuxtApp.$sentryCaptureException('NUXT_API_SECRET key not found! NUXT_API_SECRET is required to get a session token from the API server!');
    } else {
      console.error('NUXT_API_SECRET key not found! NUXT_API_SECRET is required to get a session token from the API server!');
    }
  }

  // Maintenance access key. This key is used to access the site during maintenance mode.
  // - Open server middleware and uncomment (and set an access key) the maintenance mode code to enable maintenance mode.
  // - Open your browser and create a cookie with the name "key" and set the access key value.
  const key = useCookie('key');

  // Create headers
  const headers = new Headers([
    ['Accept', 'application/json'],
    ['Content-Type', 'application/json'],
    ['clientUid', String(runtimeConfig.public.clientUid)],
    ['Session-Token', String(authStore.sessionToken)],
    ['Access-Key', `${key.value}`],
  ]);

  // Make the api call to the server to authenticate the session
  const response = await $fetch(`${runtimeConfig.public.apiUrl}/auth-session`, {method: 'post', body: {secret: apiSecret}, headers});

  // Set token data
  if ((response as any).code === 200 && (response as any).data && (response as any).data.token) {
    authStore.setSessionToken((response as any).data);
    return;
  }
}
