import {get, post, put, deletes} from '~/api';

export default defineNuxtPlugin(() => {
  return {
    provide: {
      api: {
        get,
        post,
        put,
        deletes,
      },
    },
  };
});
