import { defineStore } from 'pinia';
import {DataTableOptions, PaginationMeta} from '~/@types/common';
import {Company} from '~/@types/company';

export interface CompanyListData {
  companies: Company[];
  meta: PaginationMeta | null;
}

export interface CompanyState {
  listParams: DataTableOptions;
  companyListData: CompanyListData;
  myCompany: Company | null;
  triggerReload: string; // when this change, it will trigger single company reload (better use the existing timestamp as the value)
}

export const useCompanyStore = defineStore('company', {
  state: (): CompanyState => ({
    listParams: {
      page: 1,
      sortBy: '[]',
      pageCount: 0,
      itemsPerPage: 10,
      filters: [],
    },
    companyListData: {
      companies: [],
      meta: null,
    },
    myCompany: null,
    triggerReload: '',
  }),
  persist: {
    paths: ['listParams', 'myCompany'],
    storage: persistedState.cookiesWithOptions({
      maxAge: 3600 * 1000 * 24 * 365,
    }),
  },
  actions: {
    setListParams (listParams: DataTableOptions) {
      this.listParams = listParams;
    },
    setListData (companyListData: CompanyListData) {
      this.companyListData = companyListData;
    },
    resetPage () {
      this.listParams.page = 1;
    },
    setMyCompany (company: Company | null) {
      this.myCompany = company;
    },
    setTriggerReload (value: string) {
      this.triggerReload = value;
    },
  },
});
